import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import Header from "@components/header/Header";
// import ContentHeader from "@components/modules/content-header/";
// import ArticleTextOnly from "@components/article-text/only-article-text";
import Footer from "@components/footer/";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentHeaderImageBtn from "@components/modules/content-header-image/content-header-image-btn";

const NotFoundPage = () => {
    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "our-client-get-started-tile.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)
    const btn = [
        {
            id: 1231,
            btnClass: 'btn btn-primary',
            Label: 'Contact Us',
            page: {
                Slug: 'contact'
            }
        }
    ]
    return(
        <Layout>
            <SEO title="404: Not found" />
            <Header classNameHeader=" header-transparent" />
            <ContentHeaderImageBtn
                Title="Page not found"
                imgUrl={imgUrl.file.childImageSharp.fluid}
                ButtonData={btn}
                pageName_1="404"
                pageLink_1="#"
                // HideBreadcrumbs={false}
            />
            {/* <Container>
                <Row>
                    <Col xs={12} lg={8}>
                        <ArticleTextOnly
                            sectionClass=" mb-md-60 mb-lg-100"
                            text="You just hit a route that doesn&#39;t exist... the sadness."
                        />
                    </Col>
                </Row>
            </Container> */}
            <Footer />  
        </Layout>
    )
}

export default NotFoundPage
